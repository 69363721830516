export const environment = {
    production: true,
    API_URL : 'https://sift-backend-qa.conagra.com',
    ORCH_API_URL : 'https://siftorchapi-qa.conagra.net',
    AUTH_TOKEN_URL : 'https://sift-qa.conagra.com',
    MS_CLIENT_ID: '664f2cf3-6bba-498b-80f4-07e57ebaf335',
    MS_AUTHORITY: 'https://login.microsoftonline.com/6b0d6faa-4dc1-485c-ac89-6a4aae225fbd',
    REDIRECT_URI: 'https://sift-qa.conagra.com',
    TENANT_ID:'6b0d6faa-4dc1-485c-ac89-6a4aae225fbd',
    LOGOUT_URL: 'https://sift-qa.conagra.com/.auth/logout?post_logout_redirect_uri=https://sift-qa.conagra.com/',
    appInsights: {
      instrumentationKey: '82dea291-0272-4b07-b6a2-1679b8a73779'
    },
    APPROVAL_URL:'https://prod-02.northcentralus.logic.azure.com:443/workflows/455fa89dd0b2429aacf920ba99ae02a4/triggers/manual/paths',
    APPROVE_REJECT_URL:'https://prod-08.northcentralus.logic.azure.com:443/workflows/24cc46e5df8f412c9c1882f5356cddda/triggers/manual/paths',
    ATTACHMENT_URL: 'https://prod-03.northcentralus.logic.azure.com:443/workflows/43e21dea1d4d47378266c4a750162f63/triggers/manual/paths',
    ACTIVATION_URL:'https://prod-23.northcentralus.logic.azure.com/workflows/47588d50862b4339a609c0cd7ed11a61/triggers/manual/paths'
  };
